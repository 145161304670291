import { StatusAlert } from "../common/components/StatusAlert.js"
import ApiService from '../common/api.service.js'

let AdminTools = {
    name: "AdminTools",
    props: {
        enableTypes: Array,
        websiteName: String
    },
    data() {
        return {
            isFormSubmitting: false,
            isShowAlert: false,
            isSuccess: false,
            responseMsg: null,
            errorMsg: null, // For facebook performance report only
            facebookInterval: 'Lifetime',
            reportStartDate: new Date(),
            reportEndDate: new Date()
        }
    },
    methods: {
        runStrategicAdDelivery() {
            this.isFormSubmitting = true
            this.isShowAlert = false
            this.errorMsg = null

            ApiService.doRequest('post', `../admin_tools/${this.websiteName}/run-prepare`, {}).then(response => {
                this.onApiSuccess(response.message)
            }).catch(error => {
                this.onApiFailed()
            });
        },
        runFacebookReport() {
            this.isFormSubmitting = true
            this.isShowAlert = false

            let formData = {
                start_date: this.formatDate(this.reportStartDate),
                end_date: this.formatDate(this.reportEndDate),
                interval_dropdown: this.facebookInterval
            }

            ApiService.doRequest('post', `../api/${this.websiteName}/facebook/report`, { ...formData }).then(response => {
                this.onApiSuccess(response.message)
            }).catch(err => {
                this.onApiFailed(err.response.data.error)
            });
        },
        onApiSuccess(message) {
            this.responseMsg = message
            this.isFormSubmitting = false
            this.isShowAlert = true
            this.isSuccess = true
        },
        onApiFailed(message = null) {
            this.isSuccess = false
            this.isFormSubmitting = false
            this.isShowAlert = true
            this.responseMsg = null
            if (message) this.errorMsg = message
        },
        onFacebookIntervalUpdated(interval) {
            console.log("changes", interval)
            this.facebookInterval = interval;
        },
        onFacebookDateRangeUpdated(type, value) {
            if (type === 'start') {
                this.reportStartDate = value
            } else {
                this.reportEndDate = value
            }
        },
        formatDate(date) {
            console.log('the daye', date)
            return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
        }
    },
    computed: {
        isFacebookEnabled() {
            return this.enableTypes.includes('facebook')
        },
    },
    components: {
        StatusAlert
    },
    template: `
    <div class="admin-tools-container">
        <StatusAlert :isSuccess="isSuccess" :successMessage="responseMsg" :errorMessage="errorMsg" :isShowAlert="isShowAlert" />
        <h5>Admin Tools</h5>
        <div class="mb-3 border p-3 bg-light d-flex align-items-center justify-content-between default-theme-border-radius shadow">
            <h6 class="m-0">Strategic Ad Delivery</h6>
            <b-button :disabled="isFormSubmitting" variant="primary" class="" @click="runStrategicAdDelivery()">Run</b-button>
        </div>

        <div v-if="isFacebookEnabled" class="mb-3 border p-3 bg-light default-theme-border-radius shadow">
            <div class="d-flex align-items-center justify-content-between">
                <h6 class="m-0">Facebook Performance Report</h6>
                <b-button :disabled="isFormSubmitting" variant="primary" class="" @click="runFacebookReport()">Run</b-button>
            </div>

            <label class="my-1 mr-2" for="inlineFormCustomSelectPref">Select Range for Report</label>
            <select class="custom-select my-1 mr-sm-2 p-2 mb-3" id="inlineFormCustomSelectPref" name='interval_dropdown' v-model="facebookInterval">
                <option value="Lifetime" selected="selected">Lifetime</option>
                <option value="Monthly" selected="selected">Monthly</option>
                <option value="Range" selected="selected">Custom Range</option>
            </select>

            <div v-if="(facebookInterval === 'Range')" class="datepicker-container d-flex">
                <div class="w-50 mr-1">
                    <label for="start-datepicker">Start date*</label>
                    <b-form-datepicker id="start-datepicker" v-model="reportStartDate" class="mb-2" required></b-form-datepicker>
                </div> 
                <div class="w-50 ml-1">
                    <label for="end-datepicker">End date*</label>
                    <b-form-datepicker id="end-datepicker" v-model="reportEndDate" class="mb-2" required></b-form-datepicker>
                </div>
            </div>
        </div>
    </div>
    `
}

export { AdminTools };
