import ApiService, { WebsitesService } from '../common/api.service.js';
import { ConfirmationModal } from "../common/components/ConfirmationModal.js"
import { StatusAlert } from "../common/components/StatusAlert.js"

let Integrations = {
    name: "Integrations",
    props: {
        websiteName: String,
        hasFacebookTesterRole: Boolean,
    },
    data() {
        return {
            isShowDisableConfirmation: false,
            tempDisablePlatformType: null,
            enabledPlatforms: [],
            disabledPlatforms: [],
            gcmHostValue: null,
            isSuccess: false,
            responseMsg: null,
            isShowAlert: false
        }
    },
    methods: {
        resetAlert() {
            this.isShowAlert = false
            this.responseMsg = null
        },
        showSuccessAlert(message) {
            this.isSuccess = true
            this.isShowAlert = true
            this.responseMsg = message
        },
        showErrorAlert() {
            this.isSuccess = false
            this.isShowAlert = true
            this.responseMsg = null
        },
        getPlatformSettingsUrl(type) {
            return `./integrations/${type}`
        },
        onDisableClicked(type) {
            this.resetAlert()
            this.tempDisablePlatformType = type
            this.isShowDisableConfirmation = true
        },
        onDisableConfirmationCanceled() {
            this.tempDisablePlatformType = null
            this.isShowDisableConfirmation = false
        },
        disablePlatform() {
            ApiService.doRequest('post', `./integrations/${this.tempDisablePlatformType}/disable`, {}).then(response => {
                this.showSuccessAlert(response.message)
                this.getPlatforms()
                this.isShowDisableConfirmation = false
            }).catch(err => {
                this.showErrorAlert()
                this.isShowDisableConfirmation = false
            });
        },
        enablePlatform(type) {
            window.location.href = `./integrations/${type}/enable`
        },
        getWebsite() {
            WebsitesService.get(`${this.websiteName}/`).then(response => {
                this.gcmHostValue = response.account.gcm_host
                this.getPlatforms()
            })
        },
        getPlatforms() {
            ApiService.doRequest('get', `../api/${this.websiteName}/platforms/statuses/`, {}).then(response => {
                const { enabled_platforms, disabled_platforms, absent_platforms } = response;
                this.enabledPlatforms = enabled_platforms;
                const combinedDisabledPlatforms = disabled_platforms.concat(absent_platforms);
                this.disabledPlatforms = this.sortArrayObjectsByName(combinedDisabledPlatforms);
            })
        },
        sortArrayObjectsByName(platformsArray){
            let result = platformsArray
            result.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            });
            return result
        },
    },
    components: {
        ConfirmationModal,
        StatusAlert
    },
    mounted() {
        this.getWebsite()
    },
    template: `
    <div class="integrations-container">
        <StatusAlert :isSuccess="isSuccess" :successMessage="responseMsg" :isShowAlert="isShowAlert" />
        <h5 class="mb-3">Integration</h5>
            <div v-if="enabledPlatforms.length" class="mb-5">
                <h6>Active</h6>
                <b-list-group>
                    <b-list-group-item v-for="platform in enabledPlatforms" :key="platform.type" class="d-flex justify-content-between">
                        <a class="btn" :href="getPlatformSettingsUrl(platform.type)">{{ platform.name }}</a>
                        <b-button @click="onDisableClicked(platform.type)">Disable</b-button>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-if="disabledPlatforms.length" class="">
                <h6>Inactive</h6>
                <b-list-group>
                    <b-list-group-item  v-for="platform in disabledPlatforms" :key="platform.type" class="d-flex justify-content-between">
                        <a class="btn" disabled>{{ platform.name }}</a>
                        <b-button variant="primary" @click="enablePlatform(platform.type)">Enable</b-button>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <ConfirmationModal
            :show="isShowDisableConfirmation"
            title="Disable Integration"
            message="Are you sure you want to disable this integration?"
            @confirmed="disablePlatform"
            @cancelled="onDisableConfirmationCanceled" />
    </div>
    `
}

export { Integrations };