import { VueEllipseProgress } from "vue-ellipse-progress";

let AdListStatus = {
    props: {
        ad: Object,
    },
    data: function () {
        return ({})
    },
    computed: {
        videoProgress: function () {
            // return null if there is error so we dont show progress bar
            return this.ad.progress >= 0 ? this.ad.progress : null
        },
        adCampaigns: function () {
            return this.currentAd?.campaigns ?? this.ad.campaigns
        },
        firstErrorMessage: function () {
            const firstCampaignsWithError = this.adCampaigns.find((adCampaign) => adCampaign.status === "error")
            if (firstCampaignsWithError !== undefined) {
                return `Error occurred on ${firstCampaignsWithError.campaign_name}`
            } else {
                return this.ad.error_message ?? "An error occurred.";
            }
        },
        firstLoadingCampaign: function () {
            const firstLoadingCampaign = this.adCampaigns.find((adCampaign) => adCampaign.status === "associating" || adCampaign.status === "dissociating");
            if(firstLoadingCampaign){
                return firstLoadingCampaign.status === "associating" ? "Assigning" : "Unassigning";
            }
            return this.ad.associated === "associating" ? "Assigning" : "";
        },
        progressStatus: function () {
            if (this.ad.progress === -1) {
                return "No video created for this ad";
            } else if (this.ad.progress === -2) {
                return "Some or all videos went wrong during rendering";
            } else {
                return `${this.ad.progress}% Rendered`;
            }
        }
    },
    methods: {
        hasRenderErrors() {
            return this.ad.progress < 0;
        },
        hasVideosRendering(){
            return this.ad.type === 'video' ? (this.videoProgress !== 100 && this.videoProgress >=0) ? true : false : false
        },
        isAnyLoading() {
            if(this.adCampaigns.length > 0){
                const campaign = this.adCampaigns.find((adCampaign) => adCampaign.status === "associating" || adCampaign.status === "dissociating")
                return campaign !== undefined
            }else{
                return this.ad.associated === "associating"
            }
        },
        isRestricted(){
            const campaign = this.adCampaigns.find((adCampaign) => adCampaign.status === "restricted")
            return campaign !== undefined
        },
        isItemSetDeleted(){
            const campaign = this.adCampaigns.find((adCampaign) => adCampaign.status === "itemset_deleted")
            return true ? (this.ad.itemset.deleted || campaign !== undefined) : false
        },
        hasNoItems() {
            const campaign = this.adCampaigns.find((adCampaign) => adCampaign.status === "empty")
            return campaign !== undefined
        },
        hasAdvertiserAssociated() {
            return this.ad.associated === "ready"
        },
        hasNoCampaigns() {
            return this.adCampaigns.length === 0
        },
        isAnyErrored() {
            const campaign = this.adCampaigns.find((adCampaign) => adCampaign.status === "error" || adCampaign.status === undefined)
            return campaign !== undefined || (this.hasNoCampaigns() && this.ad.error_message !== null);
        },
        hasAllCampaignsExpired() {
            return this.ad.campaigns_expired
        },
    },
    components: {
        'vue-ellipse-progress' : VueEllipseProgress
    },
    template: `
        <vue-ellipse-progress v-if="hasVideosRendering()" v-b-tooltip.hover :title="progressStatus"
        :progress="videoProgress"
        color="#6c757d"
        :size="16"
        :thickness="2"
        :legend="false"
        />
        <font-awesome-icon v-else-if="hasRenderErrors()" v-b-tooltip.hover :title="progressStatus" style="color:red" :icon="['fas', 'triangle-exclamation']" />
        <b-spinner v-else-if="isAnyLoading()" small :label="firstLoadingCampaign" v-b-tooltip.hover :title="firstLoadingCampaign"></b-spinner>
        <font-awesome-icon v-else-if="isAnyErrored()" v-b-tooltip.hover :title="firstErrorMessage" style="color:red" :icon="['fas', 'triangle-exclamation']" />
        <font-awesome-icon v-else-if="isRestricted()" v-b-tooltip.hover title="Impressions limit reached" style="color:orange" :icon="['fas', 'triangle-exclamation']" />
        <font-awesome-icon v-else-if="hasAllCampaignsExpired()" v-b-tooltip.hover title="All campaigns have expired" style="color:orange" :icon="['fas', 'triangle-exclamation']" />
        <font-awesome-icon v-else-if="isItemSetDeleted()" v-b-tooltip.hover title="This ad attached to deleted Itemset" style="color:orange" :icon="['fas', 'triangle-exclamation']" />
        <font-awesome-icon v-else-if="hasNoItems()" v-b-tooltip.hover title="Inactive ad due to empty itemset" style="color:orange" :icon="['fas', 'triangle-exclamation']" />
        <font-awesome-icon v-else-if="hasAdvertiserAssociated()" v-b-tooltip.hover title="Ad Associated to Advertiser" style="color:green" :icon="['fas', 'circle']" />
        <font-awesome-icon v-else-if="hasNoCampaigns()" v-b-tooltip.hover title="No campaigns assigned" style="color:orange" :icon="['fas', 'circle']" />
        <font-awesome-icon v-else v-b-tooltip.hover title="Ready" style="color:green" :icon="['fas', 'circle']" />
    `
}

export {AdListStatus};
