import Vue from 'vue';
import VueRouter from 'vue-router';
import { General } from '../advertiserSettings/General.js'
import { DataSource } from '../advertiserSettings/DataSource.js'
import { Integrations } from '../advertiserSettings/Integrations.js'
import { ConversionType } from '../ConversionType.js';
import { AdminTools } from '../advertiserSettings/AdminTools.js'
import { AutoBGRemoval } from "../advertiserSettings/AutoBGRemoval.js"
import { ThemesList } from '../themes/ThemesList.js'
import { ThemeDetails } from '../themes/ThemeDetails.js'
import { VersionDetails } from '../themes/VersionDetails.js'
import { ThemePreview } from '../themes/ThemePreview.js'
// Use dynamic import
const AdvertiserSettingsEntry = () => import('../advertiserSettings/AdvertiserSettingsEntry.js').then(module => module.AdvertiserSettingsEntry);

Vue.use(VueRouter);

const defaultPageTitle = 'Advertiser Settings';

const routes = [
  {
    path: '', component: AdvertiserSettingsEntry, name: 'main', props: true,
    redirect: 'general', // Redirect to the General tab by default
    meta: {
      pageTitle: defaultPageTitle,
    }
  },
  {
    path: '/general', component: General, name: 'general', props: true, // General
    meta: {
      pageTitle: defaultPageTitle,
    }
  },
  {
    path: '/data-source', component: DataSource, name: 'dataSource', props: true, 
    meta: {
      pageTitle: defaultPageTitle,
    } 
  },
  {
    path: '/integrations', component: Integrations, name: 'integrations', props: true, 
    meta: {
      pageTitle: defaultPageTitle,
    } 
  },
  {
    path: '/pixel-and-conversions', component: ConversionType, name: 'pixelAndConversions', props: true, 
    meta: {
      pageTitle: defaultPageTitle,
    } 
  },
  {
    // TODO: update component
    path: '/admin-tools', component: AdminTools, name: 'adminTools', props: true, 
    meta: {
      pageTitle: defaultPageTitle,
    } 
  },
  {
    path: '/auto-bg-removal', component: AutoBGRemoval, name: 'autoBgRemoval', props: true,
    meta: {
      pageTitle: defaultPageTitle,
    }
  },
  // Use our own routes here instead of using Themes.js
  ...prefixRoutes('/custom-themes', [
    {
      path: '', component: ThemesList, name: 'theme-versions-list', props: true, meta: {
        pageTitle: defaultPageTitle,
        breadcrumb: [
          { name: 'Themes List', link: 'theme-versions-list' }
        ],
        propDataKey: "customThemes"
      }
    },
    {
      path: '/theme/:themeId',
      component: ThemeDetails,
      name: 'theme-details',
      meta: {
        breadcrumb: [
          { name: 'Theme List', link: 'theme-versions-list' },
          { name: 'Theme Details' }
        ],
        propDataKey: "customThemes"
      }
    },
    // Need themeId for breadcrumb to work
    {
      path: '/theme/:themeId/version/:versionId', component: VersionDetails, name: 'version-details', meta: {
        breadcrumb: [
          { name: 'Theme List', link: 'theme-versions-list' },
          { name: 'Theme Details', link: 'theme-details' },
          { name: 'Version Details' }
        ],
        propDataKey: "customThemes"
      }
    },
    {
      path: '/preview/:id', component: ThemePreview, name: 'theme-preview', meta: {
        breadcrumb: 'Theme Preview',
        propDataKey: "customThemes"
      }
    }
  ]),
];

function prefixRoutes(prefix, routes) {
  return routes.map((route) => {
    route.path = prefix + '' + route.path;
    return route;
  });
}

const router = new VueRouter({
  routes
});

export default router;