import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";
import {AdsService, AssetsService} from './common/api.service.js'
import {LogoModal} from "./logos/LogoModal.js";
import { ColorPickerCustom } from "./ColorPickerCustom.js";
import { ConfirmationModal } from "./common/components/ConfirmationModal.js"
import { FontPickerCustom } from './ads/components/FontPickerCustom.js'

Vue.use(VueFormulate);

let AdEditHtmlDesigner = Vue.extend({
    name: "AdEditHtmlDesigner",
    data: function () {
        return {
            isSaving: false,
            defaultLogo: null,
            advanced: false,
            static_url: adretriever.STATIC_URL,
            isSavedColourPalettes: false, // Show tooltip if saved
            isSavingColourPalettes: false,// Trigger loading state for modal
            isShowSavePlatteConfirmationModal: false
        }
    },

    props: {
        store: Object,
        preset_image_thumbnail: String,
        base_preset_preview_url: String,
        website: String,
        ad_id: String,
        fonts: Array,
        custom_fonts: Array,
        preset_logo_url: String,
        media_prefix: String,
        returnURL: String,
        googleFontsApiKey: String
    },

    methods: {
        saveAd() {
            if (this.store.ad.name !== "") {
                let adService = AdsService.init(this.website)

                if (this.store.ad.theme_config.custom_fields.heading_font === "") {
                    this.store.formatCustomField('heading_font', "Roboto")
                }

                if (this.store.ad.theme_config.custom_fields.body_font === "") {
                    this.store.formatCustomField('body_font', "Roboto")
                }

                let custom_fields = Object.assign({}, this.store.getCustomFieldsForSaveAd());
                let logo = this.store.getLogo()

                let params = {
                    "name": this.store.ad.name,
                    "itemset": this.store.ad.itemset.id,
                    "theme_config": {
                        "theme_version_id": this.store.ad.theme_config.theme_version.id,
                        "custom_fields": custom_fields
                    },
                    "logo": logo ? logo.id : null
                }
                if (this.ad_id !== "") {
                    adService.update(this.ad_id, params, "html")
                        .then(response => {
                            window.location.href = this.returnURL;
                        })
                } else {
                    adService.create(params, "html")
                        .then(response => {
                            window.location.href = this.returnURL;
                        })
                }
            } else {
                this.store.changeAdNameErrorStatus(true)
            }
        },

        onSaveColourPaletteButtonClicked(){
            this.isShowSavePlatteConfirmationModal = true
        },

        saveColourPalette() {
            let colourFieldsInAdTheme = this.store.getColorFields();
            let currentLogoId = this.store.getLogo().id
            this.isShowSavePlatteConfirmationModal = false

            AssetsService.update(currentLogoId, {palette: colourFieldsInAdTheme})
                .then(response => {
                    // Get logo again after update
                    this.getLogoFromServer(response.data.id);
                    // Show saved tooltip with a delay to avoid dismissed by the confirm modal
                    setTimeout(() => { 
                        this.isSavedColourPalettes = true
                    }, 300);
                    
                    // Hide tooltip after showing
                    setTimeout(() => { 
                        this.isSavedColourPalettes = false
                    }, 5000);
                }
            );
        },

        getLogoFromServer(logoId) {
            AssetsService.get(logoId, {only: this.store.logoDetailFields, type: 'logo'})
                .then(response => {
                    if (response) {
                        this.store.updateLogo(response);
                    }
                }
            );
        },

        updateStoreColor(name, value){
            this.store.updateCustomField(name, value)
        },

        updateFont(font, customFieldKey) {
            this.store.updateCustomField(customFieldKey, font.family)
        }
    },

    computed: {
        all_fonts: function(){
            return this.fonts.concat(this.custom_fonts).sort()
        },
        button_size: function() {
            // If we have a logo then we get the size props from the image to allow for scaling
            return this.store.getLogo() ? "" : "logo-button-size"
        },
        isThemeColourDifferentFromDefault: function() {
            return this.store.isThemeColourDifferentFromDefault()
        },
    },
    components: {
        LogoModal,
        ColorPickerCustom,
        ConfirmationModal,
        FontPickerCustom
    },
    template:
        `
    <div class="html-edit row h-100 flex-column flex-nowrap no-gutters">
        <div class="col-12">
            <div class="brand-section design-section mb-4 row">
                <div class="col-12">
                    <div class="row w-100 border-bottom mb-3">
                        <h5>Brand</h5>
                    </div>
                    <div class="row mb-5 flex-column">
                        <h6 class="mt-2">Logo</h6>
                        <b-button class="logo-upload-button w-75" :class="['icon-button', button_size]" variant="light" v-b-modal.modal-logo>
                        <img v-if="store.getLogo()" :src="store.getLogo().asset" alt="image" height="100" width="200" class="img-fluid">
                        <font-awesome-icon v-else :icon="['fas', 'image']" />
                        </b-button>
                        <LogoModal :preset_image_thumbnail="preset_image_thumbnail" :website="website" :store="store"></LogoModal>
                    </div>
                    <div class="row">
                        <h6>Palette</h6>
                    </div>
                    <div class="row mb-3">
                        <div class="col col-sm-9 col-lg-auto mr-4">
                            <div class="row align-items-center flex-nowrap">
                                <font-awesome-icon class="col-1 px-0 mr-2 flex-grow-0 palette-icons" :icon="['fas', 'fill']" size="2xl" />
                                <div class='col p-0 pb-3 pr-2 mr-2 flex-grow-0 border-right'>
                                    <ColorPickerCustom :colorValue="store.ad.theme_config.custom_fields.primary" customFieldsName="primary" @updateStoreColor="updateStoreColor" />
                                </div>
                                <div class='col p-0 pb-3 pr-2 mr-2 flex-grow-0 border-right'>
                                    <ColorPickerCustom :colorValue="store.ad.theme_config.custom_fields.secondary" customFieldsName="secondary" @updateStoreColor="updateStoreColor" />
                                </div>
                                <div class='col px-0 pb-3 flex-grow-0'>
                                    <ColorPickerCustom :colorValue="store.ad.theme_config.custom_fields.tertiary" customFieldsName="tertiary" @updateStoreColor="updateStoreColor" />
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center flex-nowrap">
                                <font-awesome-icon class=" col-1 px-0 mr-2 flex-grow-0 palette-icons" :icon="['fas', 'font']" size="2xl" />
                                <div class='col p-0 pr-2 mr-2 flex-grow-0 border-right'>
                                    <ColorPickerCustom :colorValue="store.ad.theme_config.custom_fields.primary_text" customFieldsName="primary_text" @updateStoreColor="updateStoreColor" />
                                </div>
                                <div class='col p-0 pr-2 mr-2 flex-grow-0 border-right'>
                                    <ColorPickerCustom :colorValue="store.ad.theme_config.custom_fields.secondary_text" customFieldsName="secondary_text" @updateStoreColor="updateStoreColor" />
                                </div>
                                <div class='col px-0 flex-grow-0'>
                                    <ColorPickerCustom :colorValue="store.ad.theme_config.custom_fields.tertiary_text" customFieldsName="tertiary_text" @updateStoreColor="updateStoreColor" />
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-start align-items-center">
                            <div class="save-colour-palette-btn row mb-3">
                                <button id="save-colour-palette-tooltip-target" @click="onSaveColourPaletteButtonClicked" class="btn" :disabled="isThemeColourDifferentFromDefault ? false : true">
                                    <font-awesome-icon v-if="isThemeColourDifferentFromDefault" :icon="['far', 'bookmark']" size="lg" />
                                    <font-awesome-icon v-else :icon="['fas', 'bookmark']" size="lg" />
                                </button>
                                <b-tooltip
                                :show="isSavedColourPalettes"
                                id="save-colour-palette-tooltip"
                                target="save-colour-palette-tooltip-target"
                                placement="top"
                                variant="success"
                                triggers="">
                                    <p class="text-left m-0">
                                        <font-awesome-icon :icon="['far', 'circle-check']" />
                                        This palette is saved as the default for this logo.
                                    </p>
                                </b-tooltip>
                                <confirmation-modal
                                :show="isShowSavePlatteConfirmationModal"
                                title="Save default colour palette"
                                message='Do you want to make this default colour palette for the current logo?'
                                @confirmed="saveColourPalette"
                                @cancelled="isShowSavePlatteConfirmationModal = false"
                                ></confirmation-modal>
                                <div :class="isThemeColourDifferentFromDefault ? 'd-none' : ''" id="default-colour-palette-tooltip-target" class="position-absolute p-2 pl-3 hide">
                                    <font-awesome-icon :icon="['fas', 'bookmark']" size="lg" />
                                </div>
                                <b-tooltip
                                id="default-colour-palette-tooltip"
                                target="default-colour-palette-tooltip-target"
                                placement="top"
                                variant="success"
                                triggers="hover">
                                    <p class="text-left m-0">
                                        <font-awesome-icon :icon="['far', 'circle-check']" />
                                        This palette is saved as the default for this logo.
                                    </p>
                                </b-tooltip>
                            </div>
                        </div>
                        <div class="confirm-save-colour-palette-modal modal fade" id="confirmSaveColourPalette" ref="confirmSaveColourPalette" tabindex="-1" role="dialog" aria-labelledby="confirmSaveColourPaletteLabel" aria-hidden="true" data-backdrop="false">
                            <div class="modal-backdrop fade show"></div>
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <span v-if="isSavingColourPalettes">Saving colour palette...</span>
                                        <span v-else>Do you want to make this default colour palette for the current logo?</span>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" data-target="#confirmSaveColourPalette">No, cancel</button>
                                        <button type="button" class="btn btn-primary" @click="saveColourPalette">Yes, save as default</button>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="imageSize" class="h5 font-weight-bold">Main Image Sizing:</label>
                <select id="imageSize" v-model="store.ad.theme_config.custom_fields.scaling" class="mx-2">
                  <option value="contain">Fit</option>
                  <option value="cover">Fill</option>
                </select>
            </div>
            <div class="copy-section design-section mb-4 row">
                <div class="col-12">
                    <div class="row w-100 border-bottom mb-3">
                        <h5>Copy</h5>
                    </div>
                    <div class="row">
                        <FormulateInput
                        class="col-11 mb-3 p-0 font-weight-bold"
                        type="text" label="Call to Action (CTA)" v-model="store.ad.theme_config.custom_fields.call_to_action" :input-class="['form-control']"
                        :wrapper-class="['mb-2']" placeholder="Enter a Call to Action (eg. SHOP NOW)"
                        />
                    </div>
                    <div class="row">
                        <FormulateInput
                        class="col-11 mb-3 p-0 font-weight-bold"
                        type="text" label="Headline" v-model="store.ad.theme_config.custom_fields.headline"
                        :wrapper-class="['mb-2']" :input-class="['form-control']" placeholder="Enter a Headline"
                        />
                    </div>
                </div>
            </div> 
            <div class="advanced-options-section design-section row">
                <div class="col-12">
                    <div
                    class="mb-4 border-bottom row w-100 justify-content-between align-items-center"
                    :class="advanced ? null : 'collapsed'"
                    :aria-expanded="advanced ? 'true' : 'false'"
                    aria-controls="advancedOptions"
                    @click="advanced = !advanced"
                    >
                        <h5>Advanced options</h5>
                        <font-awesome-icon class="arrow-down" :icon="['fas', 'chevron-down']" />
                    </div>
                    <b-collapse id="advancedOptions" v-model="advanced" class="mb-2 row">
                        <div class="col-12">
                            <div class="row">
                                <label class="font-weight-bold">Body Font</label>
                            </div>  
                            <div class="row mb-3 align-items-center">
                                <FontPickerCustom 
                                class="" 
                                :fontList="all_fonts" 
                                pickerId="bodyFont"
                                customFieldKey="body_font"
                                @updateFont="updateFont" 
                                :defaultValue="store.ad.theme_config.custom_fields.body_font"
                                :googleFontsApiKey="googleFontsApiKey" />
                                <font-awesome-icon class="angle-down-icon" :icon="['fas', 'angle-down']" />
                            </div>
                            <div class="row">
                                <label class="font-weight-bold">Heading Font</label>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <FontPickerCustom 
                                class="" 
                                :fontList="all_fonts" 
                                pickerId="headingFont" 
                                customFieldKey="heading_font"
                                @updateFont="updateFont" 
                                :defaultValue="store.ad.theme_config.custom_fields.heading_font"
                                :googleFontsApiKey="googleFontsApiKey" />
                                <font-awesome-icon class="angle-down-icon" :icon="['fas', 'angle-down']" />
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
        <div class="col-4 footer position-fixed border w-100 d-flex justify-content-center">
            <button class="btn btn-primary my-2 w-50" v-on:click="saveAd()" :disabled="isSaving">Save</button>
        </div> 
    </div>
    `
});

export {AdEditHtmlDesigner};
