// noinspection ES6PreferShortImport
import {BaseList} from "../BaseList.js";
import ApiService, {UserService, BulkAdsService} from "../common/api.service.js";
import {AdListStatus} from "../AdListStatus.js";

let AdsTable = {
    components: {
        "r-table": BaseList,
        "ad-status": AdListStatus,
    },
    props: {
        fields: {
            type: Array[Object],
            default: function () {
                return [
                    {
                        key: 'selected',
                    },
                    {
                        key: 'name',
                        label: "Name",
                        sortable: true,
                        tdClass: 'd-flex justify-content-between'
                    },
                    {
                        key: 'status',
                        label: "Status",
                    },
                    {
                        key: 'itemset',
                        label: "Item Set",
                        sortable: true,
                        formatter: value => { return value.name },
                        thClass: 'col-2'
                    },
                    {
                        key: 'type',
                        label: "Type",
                    },
                    {
                        key: 'theme_config',
                        label: "Theme",
                        formatter: value => {
                            return (value.theme_version && value.theme_version.name) ? value.theme_version.name: ''
                        }
                    },
                    {
                        key: `assigned`,
                        label: "Campaigns"
                    },
                    {
                        key: 'created_at',
                        label: "Created",
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: ''
                    },
                ]
            }
        },
        resource: String,
        website: String,
        platformEnabled: Boolean,
        pollingInterval: Number,
        approved_default_ad: Boolean,
        showDefaultAlert: Boolean,
        createDefaultAds: String,
        userId: String
    },
    data: function () {
        return ({
            sortBy: '',
            sortDesc: '',
            adUrl: '/{website}/ad/{id}/edit',
            manageCampaignUrl: '/{website}/ad/{id}',
            associateAdvertiserUrl: '/{website}/ad/{id}/associate',
            deleteDisplayAdUrl: '/{website}/ad/{type}/{id}/delete',
            getTagsUrl: '/{website}/ad/{id}/tags',
            getVideosUrl: '/{website}/ad/{id}/videos',
            expand: 'theme_config__theme_version,itemset,theme_config,campaigns',
            only: 'id,name,campaigns,website_name,theme_config__theme_version,theme_config__name,theme_config__theme_version_id,theme_config__url_params,itemset__name,itemset__deleted,itemset_id,itemset__has_items,type,progress,created_at,associated,error_message,campaigns_expired',
            share_url: "",
            ad_name_modal:"",
            selectedAds: [],
            selectedAction: "",
            message: '',
            error: false,
        })
    },
    watch: {
        selectedAction: function() {
            const data = {ad_ids: this.selectedAds.map(ad => ad.id)};
            const num_ads = this.selectedAds.length;
            if (this.selectedAction === "delete" && num_ads > 0) {
                // Poor man's i18n and pluralization :S
                const ads = num_ads > 1 ? `${num_ads} ads` : `${num_ads} ad`;
                if (confirm(`Are you sure you want to delete ${ads}?`)) {
                    BulkAdsService.init(this.website).post(data, 'delete')
                        .then((response) => {
                            this.$refs.adsTable.$emit("refresh");
                        }).catch(error => {
                        this.error = true;
                        this.message = error.response.data.error;
                    });
                }
            }
            this.selectedAction = "";
        }
    },
    computed: {
        actionsAvailable: function() {
            return this.selectedAds.length > 0;
        },
        allSelected: function () {
            return this.selectedAds.length > 0 && this.selectedAds.length === this.$refs.adsTable.items.length;
        }
    },
    methods: {
        shareURL(ad) {
            console.log("shareURL", ad)
            this.ad_name_modal = ad.name
          ApiService.query('copy/ad/url', {
              'ad_id': ad.id,
              'website': ad.website_name,
              'type': ad.type,
          }).then(response => {
            console.log("???", window.location.origin + "/preview/" + response['token'])
              this.share_url = window.location.origin + "/preview/" + response['token']
          })
        },
          copyShareURL() {
              let input = document.querySelector('input');
              input.select()
              navigator.clipboard
                  .writeText(this.share_url)
                  .then(() => {console.log("successfully copied");})
                  .catch(() => {console.log("something went wrong");});
          },

        buildAdURL(buildURL, item, type) {
            if(type == 'video') {
                return buildURL({id: item.id, website: this.website}, this.adUrl + "?type=video")
            } else {
                return buildURL({id: item.id, website: this.website}, this.adUrl)
            }
        },
        buildIFrameUrl(buildURL, item, type) {
             if (type === 'video') {
                return buildURL({website: this.website, id: item.id}, "/{website}/ad/{id}/preview")
            } else if (item.theme_config !== undefined && item.theme_config.theme_version !== undefined) {
                 if(item.itemset?.has_items){
                     return `${window.location.origin}/${this.website}/template/preset/preview?` +
                     `${item.theme_config.url_params}&itemset_id=${item.itemset_id}&theme_id=${item.theme_config.theme_version.id}`;
                 }
                 else{
                     return null;
                 }
            }
        },
        handleIframeLoad(event){
          const iframe = event.target;
          const url = iframe.src;
          if(!url){
              iframe.contentWindow.document.body.innerHTML = '<div style="font-size: 25px; color: red; text-align: center; padding: 50px 0; background-color: #f9f7ec">The itemset associated to this advertisement is empty. Default advertisement is currently served.</div>';
          }
        },
        buildManageCampaignUrl(buildURL, item, type) {
            if (type === "video") {
                return buildURL({id: item.id, website: this.website}, this.manageCampaignUrl) + "?type=video"
            }
            return buildURL({
                id: item.id,
                website: this.website
            }, this.manageCampaignUrl)
        },
        buildAssociateAdvertiserUrl(buildURL, item, type) {
            if (type === "video") {
                return buildURL({id: item.id, website: this.website}, this.associateAdvertiserUrl) + "?type=video"
            }
            return buildURL({
                id: item.id,
                website: this.website
            }, this.associateAdvertiserUrl)
        },
        buildDeleteDisplayAdUrl(buildURL, item) {
            return buildURL({
                id: item.id,
                website: this.website,
                type: item.type,
            }, this.deleteDisplayAdUrl)
        },
        buildGetTagsUrl(buildURL, item) {
            return buildURL({
                id: item.id,
                website: this.website
            }, this.getTagsUrl)
        },
        buildGetVideosUrl(buildURL, item) {
            return buildURL({
                id: item.id,
                website: this.website
            }, this.getVideosUrl)
        },
        isAssigned(item) {
            return item.campaigns.length > 0
        },
        convertDatetime(date_time){
            const date = new Date(date_time)
            return date.toUTCString()
        },
        hasMultipleCampaigns(item) {
            return item.campaigns.length > 1
        },
        confirmDelete(item, event) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this ad?').then((result) => {
                if (result) {
                    ApiService.delete(`${this.website}/displayads`, item.type + '/' + item.id).then((response) => {
                        this.$refs.adsTable.$emit("refresh");
                    })
                }
            })
        },
        onRowSelected(ads) {
            this.selectedAds = ads;
        },
        onRowChanged(index, isSelected) {
            isSelected ? this.$refs.adsTable.selectRow(index) : this.$refs.adsTable.unselectRow(index);
        },
        toggleAllSelection() {
            this.allSelected? this.$refs.adsTable.clearSelected() : this.$refs.adsTable.selectAllRows();
        },
        updateAdCount(adCount){
            this.$emit('updateAdCount', adCount)
        }
    },
    template: `

    <r-table
    :fields="fields"
    :expand="expand"
    :only="only"
    :sortBy="sortBy"
    :sortDesc="sortDesc"
    :resource="resource"
    :pollingInterval="pollingInterval"
    :userId="userId"
    @updateAdCount="updateAdCount">
      <template v-slot:table="cdata">
        <div v-if="message!=='' && error" class="alert text-danger" v-bind:class="{ 'alert-success': !error, 'alert-error': error }" role="alert">{{ message }}</div>
        <div class="float-left">
            <b-alert v-if="!approved_default_ad && showDefaultAlert"  show variant="warning"> <font-awesome-icon style="color:orange" :icon="['fas', 'triangle-exclamation']" class="mr-3" size="lg"/> Paws! You have not previewed your default ad yet. Please preview your default ad. <a class="ml-4 btn btn-primary" :href="createDefaultAds">Preview Default Ad</a></b-alert>
        </div>
        <div class="text-right mb-1">
            <select v-model="selectedAction" :disabled="!actionsAvailable" class="form-control d-inline-block w-auto text-right">
              <option value="">Actions</option>
              <option value="delete">Delete</option>
              <!-- add more options for other actions -->
            </select>
        </div>
        <b-table ref="adsTable" class="default-theme-border-radius table-rounded" thead-class="default-theme-border-radius border-0 forrest-background text-white" table-variant="light" 
                 id="list-table"
                 :no-local-sorting="true"
                 :no-sort-reset="true"
                 :sort-by.sync="cdata.sortBy"
                 :sort-desc.sync="cdata.sortDesc"
                 :busy="cdata.busy"
                 @sort-changed="cdata.sortChanged"
                 :current-page="cdata.currentPage"
                 :items="cdata.items"
                 @refresh="cdata.refresh"
                 :fields="fields"
                 hover outlined no-border-collapse
                 selectable no-select-on-click
                 :select-mode="'multi'"
                 @row-selected="onRowSelected"
                 >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
        <template #head(selected)="data">
            <b-form-checkbox :checked="allSelected" @change="toggleAllSelection" class="prevent-select"/>            
        </template>
        <template #cell(name)="data">
            <a class="pr-0 pl-0" :href="buildAdURL(cdata.buildURL,data.item,data.item.type)">
                {{ data.value }}
            </a>
            <b-dropdown variant="link" toggle-class="align-self-start pt-0 primary" no-caret>
                <template #button-content>
                    <font-awesome-icon  v-if="data.item.itemset?.has_items" :icon="['fas', 'eye']" />
                    <font-awesome-icon v-else :icon="['fas', 'eye-slash']" />
                </template>
                <div class="">
                    <div style="position: absolute; top: 0; left: 0; width:95%; height:95%;z-index:2"></div>                  
                    <iframe class='m2-2'  id='ad_iframe' width="300" height="250" :src="buildIFrameUrl(cdata.buildURL,data.item,data.item.type)" @load="handleIframeLoad" scrolling="no" frameborder="0"/>
                </div>
            </b-dropdown>
	    </template>       
      
        <template #cell(selected)="data">
          <b-form-checkbox :checked="data.rowSelected" :value="true" @change="onRowChanged(data.index, !data.rowSelected)" class="primary" />
        </template>
        
        <template #cell(status)="data">
          <ad-status 
          :ad="data.item"
          />
        </template>

        <template #cell(type)="data">
            <font-awesome-icon v-if="data.item.type == 'video'" class="secondary" :icon="['fas', 'video']" />
            <font-awesome-icon v-else class="secondary" :icon="['fas', 'code']" />
        </template>
        
        <template #cell(assigned)="data">
            <p v-if="hasMultipleCampaigns(data.item)">{{ data.item.campaigns.length}} Campaigns</p>
            <p v-else-if="isAssigned(data.item)">{{ data.item.campaigns[0].campaign_name }}</p>
            <p v-else>None</p>
        </template>
        
        <template #cell(created_at)="data">
          <p>{{ convertDatetime(data.item.created_at) }}</p>
        </template>
        
        <template #cell(actions)="data">
            <b-dropdown ref="actionDropdown" right class="dropleft btn btn-outline-secondary p-0" toggle-class="btn-outline-secondary p-1 px-2 border-0">
                <template #button-content>
                    <b-button class="border-0 btn-outline-secondary p-0" size="sm">
                        <font-awesome-icon :icon="['fas', 'gear']" />
                    </b-button>
                </template>
            
                <b-dropdown-item :href="buildManageCampaignUrl(cdata.buildURL,data.item,data.item.type)">
                    Manage Campaigns
                </b-dropdown-item>

                <b-dropdown-item v-if="platformEnabled" :href="buildAssociateAdvertiserUrl(cdata.buildURL,data.item,data.item.type)">
                    Upload to GCM Advertiser
                </b-dropdown-item>

                <b-dropdown-item-button 
                v-if="isAssigned(data.item)" 
                disabled 
                v-b-tooltip.hover="{ placement: 'left' }" 
                title="To delete this ad please un-assign it from all campaigns.">
                    Delete
                </b-dropdown-item-button>
            
                <b-dropdown-item v-else @click="confirmDelete(data.item,$event)" id="delete-btn" style="cursor: pointer;">
                    Delete
                </b-dropdown-item>
            
                <b-dropdown-item v-if="isAssigned(data.item)" :href="buildGetTagsUrl(cdata.buildURL,data.item)">
                    Get Tags
                </b-dropdown-item>
            
                <b-dropdown-item-button 
                v-else
                disabled 
                v-b-tooltip.hover="{ placement: 'left' }" 
                title="To get tags ad for this please assign at least one campaign.">
                    Get Tags
                </b-dropdown-item-button>

                <b-dropdown-item v-b-modal.share-modal @click="shareURL(data.item)">
                    Share
                </b-dropdown-item>
            
                <b-dropdown-item v-if="data.item.type == 'video'" :href="buildGetVideosUrl(cdata.buildURL,data.item)">
                    Download Videos
                </b-dropdown-item>
            </b-dropdown>
        </template>

        </b-table>
          <b-modal id="share-modal" title="Share Ad" hide-footer>
            <p class="d-flex justify-content-center">Anyone with this link can see a preview of {{ ad_name_modal }} for the next 30 days.</p>
                <div class="modal-body">
                    <div class="share-field d-flex align-items-center justify-content-between">
                        <font-awesome-icon id="shareSpan" class="text-center" :icon="['fas', 'link']" />
                        <input id="shareInput" type="text" :value="share_url">
                        <button id="shareButton" class="btn btn-primary" @click="copyShareURL()">Copy</button>
                    </div>
                </div>
          </b-modal> 
      </template>
    </r-table>
    `
}

export {AdsTable};
