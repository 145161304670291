import { isEmpty } from 'lodash';

const colorFields = ['primary', 'secondary', 'tertiary', 'primary_text', 'secondary_text', 'tertiary_text']
const encodedRequiredField = ['call_to_action', 'headline', 'slogan', 'logo_url', 'scaling'] + colorFields

// FIXME: Generate a new state when instantiating/initializing the store.
//        The store state shouldn't be a global variable like this -- it's especially bad for tests where each test
//        ends up importing the same state that's been mutated by all the prior tests.
const store = () => {return {
    ad: {
        theme_config: {
            custom_fields: {
                // note that color values are without # as same when getting from retriever api,
                // but when init ad with api, you should call addHexCode to add #
                // and when you save with api, you should call removeHexCode by passing copy of this field
                slogan: "",
                primary: "#00B0B8",
                primary_text: "#FFFFFF",
                headline: "You don't want to miss out!",
                scaling: "contain",
                logo_url: "",
                tertiary: "#FFFFFF",
                tertiary_text: "#171718",
                body_font: "Roboto", // Assign default font to prevent missing one of the font will causing font url invalid
                secondary: "#F9F7EC",
                secondary_text: "#272727",
                image_index: 0,
                heading_font: "Roboto", // Assign default font to prevent missing one of the font will causing font url invalid
                call_to_action: "SHOP NOW",
                random: 0  // Used for forcing a refresh of ad previews
            },
            theme_version: {name: null, id: null}
        },
        itemset: null,
        name: "",
        logo: null,
        isDefaultColourPalette: false, // Control status of the save colour palette button, filled if this is true
    },
    state: {
        ready: false,  // if the store is ready to be rendered
        isItemSetLoaded: false,  // if we've received a response for the itemset request
        isThemeLoaded: false,  // if we've received a response for the theme request
        websiteId: null,
        itemType: null
    },
    errors: {
        adNameError: false,
        isAnySetError: false  // if no set found, set this to true
    },
    tempItemset: undefined,
    previousThemeVersion: null,
    // Define what fields we need to get from getting logo from asset
    logoDetailFields: 'id,name,asset,default,website,palette',

    init(adRes, websiteId, itemType) {
        if (adRes) {
            this.updateAd(this.normalizeThemeFields(adRes));
            this.updateTempItemset(adRes.itemset);
        }
        this.setPreviousThemeVersion();
        this.state.websiteId = websiteId;
        this.state.itemType = itemType;
        this.state.isThemeLoaded = true;
        this.state.isItemSetLoaded = true;
        this.state.ready = true;
    },

    setParentThemeWith(themeRes, presetLogoURL) {
        if (themeRes.results.length > 0) {
            this.updateParentTheme(themeRes.results[0])
            this.updateCustomFields(themeRes.results[0].custom_fields)
            if (presetLogoURL !== undefined) {
                this.updateCustomField('logo_url', presetLogoURL)
            }
        }
        this.state.isThemeLoaded = true;
        this.state.ready = this.state.isThemeLoaded && this.state.isItemSetLoaded
    },

    setThemeLoaded() {
        this.state.isThemeLoaded = true;
    },

    setItemSetWith(itemSetRes) {
        if (itemSetRes.results.length > 0) {
            this.updateItemset(itemSetRes.results[0])
            this.updateTempItemset(itemSetRes.results[0])
        }
        this.state.isItemSetLoaded = true;
        this.state.ready = this.state.isThemeLoaded && this.state.isItemSetLoaded
    },

    getParentThemeId() {
        if (this.ad.theme_config.theme_version) {
            return this.ad.theme_config.theme_version.theme_id
        }
    },
    getLogo(){
        return this.ad.logo;
    },

    isLatestVersion() {
        return this.ad.theme_config.theme_version &&
            this.ad.theme_config.theme_version.latest_version === this.ad.theme_config.theme_version.number
    },

    setPreviousThemeVersion() {
        // set the parent theme to previous theme if parent theme is not latest, so that revert later
        if (this.ad.theme_config.theme_version && !this.isLatestVersion()) {
            this.previousThemeVersion = JSON.parse(JSON.stringify(this.ad.theme_config.theme_version))
        }
    },

    updateAd(ad) {
        Object.assign(this.ad, ad)
    },

    updateParentTheme(newTheme) {
        Object.assign(this.ad.theme_config.theme_version, newTheme);
    },

    updateLogo(logo, isApplyColourToAdTheme = true) {
        this.ad.theme_config.custom_fields.logo_url = logo.asset;
        this.ad.logo = logo;
        // Check if palette is empty, if yes, the ad colour will use the hardcoded colour
        if(!isEmpty(logo.palette) && isApplyColourToAdTheme) {
            this.applyColourPaletteToAdTheme();
        }
    },

    applyColourPaletteToAdTheme(){
        let adThemeColourFields = this.getColorFields();
        for (const key in adThemeColourFields) {
            this.ad.theme_config.custom_fields[key] = this.ad.logo.palette[key]
        }
    },

    isThemeColourDifferentFromDefault() {
        if(!this.ad.logo) return true
        if(!this.ad.logo.palette) return true // Handle if a logo do not have default_colour_palette

        let adThemeColourFields = this.getColorFields();
        for (const key in adThemeColourFields) {
            // Turn value to upper case to prevent result true when compare colour code '#FFFFFF' vs '#ffffff'
            if(this.ad.theme_config.custom_fields[key]?.toUpperCase() !== this.ad.logo.palette[key]?.toUpperCase()){
                return true
            }
        }
        return false
    },

    updateCustomFields(customFields) {
        for (const key in customFields) {
            this.updateCustomField(key, customFields[key]);
        }
    },

    updateCustomField(key, value) {
        if (this.ad.theme_config.custom_fields.hasOwnProperty(key)) {
            this.ad.theme_config.custom_fields[key] = value
        } 
    },

    clearCustomFields() {
        Object.getOwnPropertyNames(this.ad.theme_config.custom_fields).forEach((prop, idx) => {
            this.ad.theme_config.custom_fields[prop] = undefined;
        })
    },

    updateTempItemset(newItemset) {
        this.tempItemset = newItemset
    },

    updateItemset(newItemset) {
        this.ad.itemset = newItemset
        this.ad.theme_config.custom_fields.image_index = 0; // Reset the image index when change set
    },

    createPreviewURL(base_preset_preview_url, templateId, themeId) {
        var custom_query = Object.keys(this.ad.theme_config.custom_fields).reduce(
            (arg_list, key) =>
                // If the custom field value is undefined, format it and add it to the list of query string args
                (this.ad.theme_config.custom_fields[key] !== undefined)
                ? arg_list.concat(key + "=" + this.formatCustomField(key, this.ad.theme_config.custom_fields[key]))
                // otherwise exclude it
                : arg_list,
            [] // Initial value for the reducer
        ).join("&");
        return base_preset_preview_url.concat(
            '?template_id=', templateId,
            '&itemset_id=', this.ad.itemset?this.ad.itemset.id:'',// Conditionally include itemset_id based on this.ad.itemset
            '&theme_id=', themeId,
            '&', custom_query)
    },

    formatCustomField(key, value) {
        if (this.isEncodedRequiredField(key)) {
            return encodeURIComponent(value)
        }
        return value
    },

    isColorField(key) {
        return colorFields.includes(key)
    },

    getColorFields() {
        let colourFields = {};
        for (const key in this.ad.theme_config.custom_fields) {
            if(this.isColorField(key)) {
                colourFields[key] = this.ad.theme_config.custom_fields[key]
            }
        }
        return colourFields;
    },

    isEncodedRequiredField(key) {
        return encodedRequiredField.includes(key)
    },

    changeAdNameErrorStatus(status) {
        this.errors.adNameError = status
    },

    // For old ads using legacy fields and add `random` field for forcing a refresh of ad previews
    normalizeThemeFields(adRes){
        let newAdRes = Object.assign({}, adRes)
        // Handle legacy fields : `body`, `heading` and `button`
        newAdRes.theme_config.custom_fields.primary_text === undefined ? newAdRes.theme_config.custom_fields.primary_text = newAdRes.theme_config.custom_fields.button : newAdRes.theme_config.custom_fields.primary_text
        newAdRes.theme_config.custom_fields.secondary_text === undefined ? newAdRes.theme_config.custom_fields.secondary_text = newAdRes.theme_config.custom_fields.heading :  newAdRes.theme_config.custom_fields.secondary_text
        newAdRes.theme_config.custom_fields.tertiary_text === undefined ? newAdRes.theme_config.custom_fields.tertiary_text = newAdRes.theme_config.custom_fields.body : newAdRes.theme_config.custom_fields.tertiary_text
        // Add `random` to force refresh of ad preview
        newAdRes.theme_config.custom_fields.random === undefined ? newAdRes.theme_config.custom_fields.random = 0 : newAdRes.theme_config.custom_fields.random
        return newAdRes
    },

    getCustomFieldsForSaveAd(){
        let allCustomFields = Object.assign({}, this.ad.theme_config.custom_fields)
        // Remove `random` fields as this was used by frontend to trigger refresh on ad preview only
        if(allCustomFields.random) delete allCustomFields.random
        return allCustomFields
    },
}}

export {
    store
};
